import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Works.css';
import Card from '../../../components/Card/Card';
import works from '../../../json/works.json'; 
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header'

const Works = (currentSection) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="works">
        <Header currentSection={currentSection} />

      <div className="jobs">
        {works.ideias.map((item) => {

          return (
            <Link to={`/case/${item.path}`} key={item.path} className="card-link"> 
              <div className="card">
                <div className="content">
                  <Card
                    img={item.img}
                    ideia={item}
                    video={item.video}
                    width="auto"
                    height="270px"
                    showDown={false}
                  />
                </div>
                <div className="text">
                  <h3>{item.title}</h3>
                  <p>{item.campaign}</p>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
      <div className="footer_section">
        <Footer
          showImg={true}
        />
      </div>
    </div>
  );
};

export default Works;
