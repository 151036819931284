import React from 'react';
import { useNavigate } from 'react-router-dom';
import Text from '../../components/Text/Text';
import Button from '../../components/Button/Button';
import './Card.css';

const Card = ({
  ideia = {},
  width = '100%',
  height = '100%',
  children,
  contentPosition = 'center',
  showDown = true,
  background = {},
  darkenBackground = false,
}) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (ideia.path) {
      navigate(`/case/${ideia.path}`);
    }
  };

  // Safari and iPhone detection functions
  const isSafari = () => {
    return (
      navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent && 
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1
    );
  };

  const isIPhone = () => {
    return /iPhone/.test(navigator.userAgent);
  };

  const isSafariBrowser = isSafari();
  const isIPhoneUser = isIPhone();

  const downStyle = {
    marginTop: isSafariBrowser && isIPhoneUser ? '10px' : '20px', // Adjust this value as needed
  };

  const renderBackground = () => {
    const backgroundSrc = background.video || background.img || ideia.video || ideia.img;

    if (!backgroundSrc) return null;

    const backgroundStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      opacity: darkenBackground ? 0.5 : 1,
    };

    if (background.video) {
      return (
        <video className="card-background" autoPlay muted loop playsInline style={backgroundStyle}>
          <source src={background.video} type="video/mp4" />
        </video>
      );
    }

    if (background.img) {
      return (
        <div
          className="card-background"
          style={{ ...backgroundStyle, background: `url(${background.img}) no-repeat center center / cover` }}
        />
      );
    }

    if (ideia.video) {
      return (
        <video className="card-background" autoPlay muted loop playsInline style={backgroundStyle}>
          <source src={ideia.video} type="video/mp4" />
        </video>
      );
    }

    if (ideia.img) {
      return (
        <div
          className="card-background"
          style={{ ...backgroundStyle, background: `url(${ideia.img}) no-repeat center center / cover` }}
        />
      );
    }

    return null;
  };

  return (
    <div
      className="card"
      style={{ width, height, position: 'relative', overflow: 'hidden' }}
    >
      <div className={`card-content ${contentPosition}`}>
        {children}
      </div>
      {renderBackground()}
      {showDown && (
        <div className="down" style={downStyle}>
          <Text text={ideia.title} />
          <div className="btn_down">
            <Button
              text={ideia.campaign}
              onClick={handleButtonClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
