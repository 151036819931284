import React, { useState, useEffect } from 'react';
import './Footer.css';

const Footer = ({ fixed, showImg }) => {
    const colors = [
        '#00AEEF',
        '#1B5CFF',
        '#7D2E61',
        '#ED0F69',
        '#ED3D24',
        '#FFD600'
    ];

    const getInitialColor = () => {
        const storedColor = localStorage.getItem('footerColor');
        return storedColor ? storedColor : 'rgb(0, 0, 0)';
    };

    const [backgroundColor, setBackgroundColor] = useState(getInitialColor());
    const [textColor, setTextColor] = useState('white');
    const [lineClass, setLineClass] = useState('white-line');
    const [isPolicyVisible, setIsPolicyVisible] = useState(false);

    const changeColor = () => {
        const currentIndex = colors.indexOf(backgroundColor);
        const nextIndex = (currentIndex + 1) % (colors.length + 1);

        if (nextIndex === colors.length) {
            const newBackgroundColor = 'rgb(0, 0, 0)';
            setBackgroundColor(newBackgroundColor);
            localStorage.setItem('footerColor', newBackgroundColor);
            setTextColor('white');
            setLineClass('white-line');
        } else {
            const newBackgroundColor = colors[nextIndex];
            setBackgroundColor(newBackgroundColor);
            localStorage.setItem('footerColor', newBackgroundColor);
            setTextColor('black');
            setLineClass('default-line');
        }
    };

    useEffect(() => {
        setTextColor(backgroundColor === 'rgb(0, 0, 0)' ? 'white' : 'black');
        setLineClass(backgroundColor === 'rgb(0, 0, 0)' ? 'white-line' : 'default-line');
    }, [backgroundColor]);

    const togglePolicyVisibility = () => {
        setIsPolicyVisible(prev => !prev);
    };

    return (
        <div className={`footer ${fixed ? 'fixed' : ''}`} style={{ backgroundColor, color: textColor }}>
            {showImg && (
                <img
                    src="/assets/content/nav/nvrfnshd.svg"
                    alt="Footer Logo"
                />
            )}
            <div className="content">
                <div className="contact">
                    <p>
                        <span>Contato</span> <br />
                        <a target="_blank" rel="noopener noreferrer" href="https://fcbbrasil.gupy.io/" className="email-link">Faça Parte</a> <br />
                        <a href="mailto:novosnegocios@fcb.com" className="email-link">novosnegocios@fcb.com</a> <br />
                        <a href="mailto:imprensa.sm@fcb.com">imprensa.sm@fcb.com</a> <br />
                        <a href="mailto:contato@fcb.com" className="email-link">contato@fcb.com</a>
                    </p>
                </div>
                <div className="family">
                    <div className="find">
                        <p>
                            <span>Endereço <br /></span>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.google.com.br/maps/place/FCB+Brasil" className="address-link">Av. das Nações Unidas, 12901, 17º andar - Torre Norte - Cidade Monções, São Paulo - SP, 04578-910 Brasil</a>
                        </p>
                    </div>
                    <div className="social">
                        <p>
                            <span>Siga</span> <br />
                            <a href="https://www.instagram.com/fcbbrasil/" target='_blank' rel="noopener noreferrer" className="social-link">Instagram</a> <br />
                            <a href="https://www.linkedin.com/company/fcb-brasil/mycompany/" target='_blank' rel="noopener noreferrer" className="social-link">Linkedin</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className={`sub_footer ${lineClass}`}>
                <div className="rights">
                    <p>© <i>FCBBR</i> 2024 Todos os Direitos Reservados</p>
                    <div className="obs">
                        <p>
                            {/* <a href="" target='_blank' rel="noopener noreferrer" className="policy-link">Cookies </a> / */}
                            <a href="/documents/standardpolicies.pdf" target='_blank' rel="noopener noreferrer" className="policy-link"> Política Anticorrupção </a> /
                            <a href="/documents/SPP556PoliticadeManifestacaodoIPG.pdf" target='_blank' rel="noopener noreferrer" className="policy-link"> Políticas de Manifestação </a> /
                            <a href="/documents/standardpolicies_presentes.pdf" target='_blank' rel="noopener noreferrer" className="policy-link"> Política de Presentes </a> /
                            <a href="/documents/IPG-Code-of-Conduct-2024-Portuguese.pdf" target='_blank' className="policy-link"> Código de Conduta </a> <br />
                            <a href="/documents/SPP382Principiosparaogerenciamentodefornecedores.pdf" target='_blank' rel="noopener noreferrer" className="policy-link"> Código de Conduta Fornecedores </a>  /                         
                            <a href="/documents/Alertline Page Inside.Interpublic - PT.pdf" target='_blank' rel="noopener noreferrer" className="policy-link"> AlertLine </a> /
                            <a href="/documents/2024 _ 2orelatorio.pdf" target='_blank' rel="noopener noreferrer" className="policy-link"> Relatório de Transparência e Igualdade Salarial</a>
                            <span>
                                {/* <a className="policy-link" onClick={togglePolicyVisibility}>Nossas Políticas</a> */}
                            </span> <br />
                        </p>
                    </div>
                </div>
                <div className="fcb"><p>Site feito por FCB/BRASIL</p></div>
                <div className="face">
                    <img src="/assets/content/nav/&_nvrfnshd_1.gif" alt="logo_gif" onClick={changeColor} />
                </div>
            </div>
        </div>
    );
};

export default Footer;
