import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Header.css';
import Menu from '../Menu/Menu';
import Logo from '../Logo/Logo';

const Header = ({ currentSection }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [logoDisplay, setLogoDisplay] = useState('flex');
    const [logoScale, setLogoScale] = useState(false); 
    const navigate = useNavigate();
    const location = useLocation();

    const isContactPage = location.pathname === '/contact';

    const handleButtonClick = () => {
        navigate("/");
    };

    
    const toggleMenu = () => {
        setIsOpen(prevState => !prevState);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            switch (true) {
                case isOpen:
                    setLogoDisplay("flex");
                    break;
                case currentSection === 0:
                    setLogoDisplay("none");
                    break;
                default:
                    setLogoDisplay("flex");
            }
        }, isOpen ? 1000 : (currentSection === 0 ? 1 : 400));

        return () => clearTimeout(timeout);
    }, [isOpen, currentSection]);

    useEffect(() => {
        if (logoDisplay === "flex") {
            setLogoScale(true);
            const scaleTimeout = setTimeout(() => {
                setLogoScale(false); 
            }, 50); 

            return () => clearTimeout(scaleTimeout);
        }
    }, [logoDisplay]);

    const handleMenuItemClick = (targetPath) => {
        if (location.pathname === targetPath) {
            setIsOpen(false);
        } else {
            navigate(targetPath);
            setIsOpen(false);
        }
    };

    return (
        <div className="header">
            <div className='gif'>
                <img style={{ display: isContactPage ? 'none' : 'block' }} src="/assets/content/nav/&_nvrfnshd_1.gif" alt="logo_gif" />
            </div>
            <Logo
                imageSrc="/assets/content/icons/logo_fcbbr_white_114x28 (1).png"
                cursorPointer={true}
                onClick={handleButtonClick}
                hideLogo={logoDisplay}
                scale={logoScale} 
            />
            <div className={`menu ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
            <Menu 
                isOpen={isOpen} 
                onLinkClick={handleMenuItemClick} 
            />
        </div>
    );
};

export default Header;
