import React, { useEffect } from 'react';
import './Festivais.css';

const Festivais = ({ festivais }) => {
    useEffect(() => {
        const container = document.querySelector('.festivais');
        const items = container.querySelectorAll('.content > .itens');

        if (items.length > 4) {
            container.classList.add('more-than-four');
        } else {
            container.classList.remove('more-than-four');
        }
    }, [festivais]);

    return (
        <div className="festivais">
            <div className="content">
                {festivais.map((festival, index) => (
                    <div key={index} className="itens">
                        <div className="title_fest">
                            <img src={festival.icon_source} alt={`Icon for ${festival.nome}`} />
                        </div>


                        <div className="contente">
                            <div className="name">{festival.nome}</div>

                            {festival.premiacoes.map((premio, premioIndex) => (
                                <div key={premioIndex} className="awards">
                                    {premio.premio && (
                                        <p className="award">
                                            {premio.premio}
                                            &nbsp;|&nbsp;
                                        </p>
                                    )}
                                    {premio.categoria && (
                                        <p className="category">
                                            {premio.categoria}
                                            <span className="amount">&nbsp;{premio.quantidade}</span>
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Festivais;
