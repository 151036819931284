import React from 'react';
import PropTypes from 'prop-types';
import './Logo.css';

const Logo = ({ width, height, cursorPointer, imageSrc, onClick, hideLogo, scale }) => {
    const logoStyle = {
        display: hideLogo, 
        cursor: cursorPointer ? 'pointer' : 'default',
        width,
        height,
        transform: scale ? 'scale(0)' : 'scale(1)',
        transition: 'transform 0.5s ease',
    };

    return (
        <div
            className="logo_content"
            style={logoStyle} 
            onClick={onClick}
        >
            <img
                src={imageSrc}
                alt="Logo"
                style={{ width: '100%', height: 'auto' }}
            />
        </div>
    );
};

Logo.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    cursorPointer: PropTypes.bool,
    imageSrc: PropTypes.string.isRequired,
    onClick: PropTypes.func, 
    hideLogo: PropTypes.string.isRequired, 
    scale: PropTypes.bool, 
};

export default Logo;
