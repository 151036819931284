import React from 'react';
import './Info3.css';

const Info3 = ({ texto, assinatura }) => {
    return (
        <div className="info3">
            <div className="contente">
                <p className='citacao'>{texto}</p> 
                <p className='assinatura'>{assinatura}</p>
            </div>
        </div>
    );
};

export default Info3;
