import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Case.css';
import Tape from '../../components/Tape/Tape';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Video from './Video/Video';
import Img from './Img/Img';
import Info from './Info/Info';
import Info2 from './Info2/Info2';
import Info3 from './Info3/Info3';
import Results from './Results/Results';
import Festivais from './Festivais/Festivais';
import Gallery from './Gallery/Gallery';

const Case = ({ currentSection }) => {
    const { path } = useParams();
    const [caseData, setCaseData] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchCaseData = async () => {
            const response = await fetch(`/ideias/${path}.json`);
            const data = await response.json();
            setCaseData(data);
        };

        fetchCaseData();
    }, [path]);

    const { resultados = {}, conteudos = [], festivais = [] } = caseData || {};
    
    const showResults = resultados.titulo && resultados.textos && resultados.textos.some(text => text.trim() !== "");

    const validFestivals = festivais.filter(festival => {
        const hasValidName = festival.nome && festival.nome.trim() !== "";
        const hasValidIcon = festival.icon_source && festival.icon_source.trim() !== "";
        const hasValidAwards = festival.premiacoes && festival.premiacoes.some(premio => 
            (premio.premio && premio.premio.trim() !== "") || 
            (premio.categoria && premio.categoria.trim() !== "")
        );
        return hasValidName || hasValidIcon || hasValidAwards;
    });

    const showFestivais = validFestivals.length > 0;

    const displayTitle = showResults || showFestivais;

    return (
        <div className="case">
            <Header currentSection={currentSection} />
            <div className="text_head">
                <div className="title">
                    <p className='title_prim'>{caseData?.titulo}</p>
                    <p><span>{caseData?.cliente}</span> {caseData?.campanha}</p>
                </div>
            </div>

            <div className="content_case content-padding">
                {conteudos.map((content, index) => {
                    switch (content.tipo) {
                        case 'texto':
                            return content.paragrafos ? <Info key={index} text={content.paragrafos} /> : null; 
                        case 'texto2':
                            return content.paragrafos ? <Info2 key={index} text={content.paragrafos} /> : null;                            
                        case 'citacao':
                            return (
                                <Info3
                                    key={index}
                                    texto={content.texto}
                                    assinatura={content.assinatura}
                                />
                            );
                        case 'imagem':
                            return content.source ? <Img key={index} src={content.source} /> : null;
                        case 'video':
                            return content.source ? (
                                <Video
                                    key={index}
                                    src={content.source}
                                    autoPlay={content.autoPlay || false}
                                    loop={content.loop || false}
                                    muted={content.muted || false}
                                    controls={content.controls !== false}
                                    poster={content['play-img']}
                                    isFirst={content.first === true}
                                />
                            ) : null;
                        case 'conteudos':
                            return (
                                <Gallery
                                    key={index}
                                    itens={content.itens}
                                    altura={content.altura}
                                    largura={content.largura}
                                />
                            );
                        default:
                            return null;
                    }
                })}
                {(showResults || showFestivais) && (
                    <div className="results-festivais-container">
                        <div className="results-content">
                            {displayTitle && (
                                 <Results
                                 title={resultados.titulo}
                                 text={showResults ? resultados.textos : []} // Pass as an array
                             />
                            )}
                            {showFestivais && (
                                <Festivais festivais={validFestivals} />
                            )}
                        </div>
                    </div>
                )}
            </div>

            <Tape />
            <div className="footer_down">
                <div className="fot">
                    <Footer showImg={true} />
                </div>
            </div>
        </div>
    );
};

export default Case;
