import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Case from '../pages/Case/Case';
import Works from '../pages/Case/Works/Works';
import Contact from '../pages/Contact/Contact';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/works" element={<Works />} />
      <Route path="/case/:path" element={<Case />} /> 
      <Route path="/contact" element={<Contact />} /> 
    </Routes>
  );
}

export default AppRoutes;
