import React, { useState } from 'react';
import './Menu.css';
import { Link } from 'react-router-dom';

const colors = ['#1db101', '#1b5cff', '#7d2e61', '#ed0e69', '#ed3d25', '#ffd601'];

const Menu = ({ isOpen, onLinkClick }) => {
  const [hoveredIndex, setHoveredIndex] = useState(0);

  const handleMouseEnter = () => {
    setHoveredIndex(prevIndex => (prevIndex + 1) % colors.length);
  };

  return (
    <div className={`nav ${isOpen ? 'open' : ''}`}>
      <Link
        to="/"
        className="nav-item"
        onClick={() => onLinkClick('/')}
        onMouseEnter={handleMouseEnter}
        style={{ '--hover-color': colors[hoveredIndex] }}
      >
        INICIO
      </Link>
      <Link
        to="/works"
        className="nav-item"
        onClick={() => onLinkClick('/works')}
        onMouseEnter={handleMouseEnter}
        style={{ '--hover-color': colors[hoveredIndex] }}
      >
        TRABALHOS
      </Link>
      <Link
        to="/contact"
        className="nav-item"
        onClick={() => onLinkClick('/contact')}
        onMouseEnter={handleMouseEnter}
        style={{ '--hover-color': colors[hoveredIndex] }}
      >
        CONTATO
      </Link>
    </div>
  );
};

export default Menu;
