import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
const colors = [
  '#00AEEF',
  '#1B5CFF',
  '#7D2E61',
  '#ED0F69',
  '#ED3D24',
  '#FFD600'
    ];

    let colorIndex = 0;

    function applySelectionColor() {
      colorIndex = (colorIndex + 1) % colors.length;

      document.styleSheets[0].insertRule(
        `::selection { background-color: ${colors[colorIndex]}; color: black; }`,
        document.styleSheets[0].cssRules.length
      );
    }

    document.addEventListener('mousedown', applySelectionColor);

root.render(
      <App />
);
