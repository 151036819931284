import React from 'react';
import './Results.css'; 

const Results = ({ title, text }) => {
    const hasText = text && text.length > 0;

    // Function to replace <strong> tags with styled underlined span
    const renderTextWithUnderline = (paragraph) => {
        return paragraph.split(/(<strong>.*?<\/strong>)/g).map((part, index) => {
            if (part.startsWith('<strong>')) {
                const content = part.replace(/<\/?strong>/g, ''); // Remove <strong> tags
                return (
                    <span key={index} className="underline">
                        {content}
                    </span>
                );
            }
            return part; // Return regular text as is
        });
    };

    return (
        <div className={`results ${hasText ? '' : 'padding'}`}>
            {title && <h2 className="results-title">{title}</h2>}
            {hasText && (
                <div className="results-text">
                    {text.map((paragraph, index) => (
                        <p key={index}>{renderTextWithUnderline(paragraph)}</p>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Results;
