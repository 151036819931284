import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../components/Logo/Logo';
import Card from '../../components/Card/Card';
import home from '../../json/home.json';
import Footer from '../../components/Footer/Footer';
import './Home.css';
import Header from '../../components/Header/Header';

const Home = () => {
  const [sections, setSections] = useState([]);
  const [currentSection, setCurrentSection] = useState(0);
  const sectionsRef = useRef([]);
  const isScrolling = useRef(false);
  const scrollPromise = useRef(Promise.resolve());
  const touchStartY = useRef(0);
  const [logoWidth, setLogoWidth] = useState('70%');
  const dragStartY = useRef(0);
  const isDragging = useRef(false);
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const scrollToSectionZero = () => {
    scrollToSection(0); 
  };

  const logosData = [
    {
      imgSrc: "/assets/content/icons/logo_mm.png",
      text: "Top 10 agências mais criativas do Brasil em 2023",
      className: 'icon_center',
    },
    {
      imgSrc: "/assets/content/icons/logo_canneslions.png",
      text: "Top 10 Brasil das agências mais premiadas dos últimos 10 anos",
      className: 'icon_center',
    },
    {
      imgSrc: "/assets/content/icons/logo_effie.png",
      text: "Top 5 agências mais premiadas em 2023",
      className: 'icon_center',
    },
    {
      imgSrc: "/assets/content/icons/logo_kantar.png",
      text: "Top 3 comerciais mais criativos e eficazes do Brasil",
      className: 'icon_center',
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIsImageLoaded(false); 
      setCurrentLogoIndex((prevIndex) => (prevIndex + 1) % logosData.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleResize = () => {
    setLogoWidth(window.innerWidth <= 800 ? '98%' : '50%');
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setSections(home.ideias);
  }, []);


  // FUNÇÃO DE ROLAGEM ENTRE AS PAGINAS
  const scrollToSection = (path) => {
    if (path >= 0 && path < sectionsRef.current.length) {

      return new Promise((resolve) => {
        const scrollContainer = document.querySelector('.scroll-container');
        scrollContainer.style.transition = 'transform 1s ease-in-out';
        scrollContainer.style.transform = `translateY(-${path * 100}vh)`;

        setTimeout(() => {
          setCurrentSection(path);
          scrollContainer.style.transition = '';
          resolve();
        }, 1000);
      });
    }
    return Promise.resolve();
  };

  const handleScroll = (delta) => {
    if (isScrolling.current) {
      return;
    }
    isScrolling.current = true;
    let newSection = currentSection;

    if (delta > 0) {
      newSection = Math.min(currentSection + 1, sectionsRef.current.length - 1);
    } else {
      newSection = Math.max(currentSection - 1, 0);
    }
    if (newSection !== currentSection) {
      scrollPromise.current = scrollPromise.current
        .then(() => scrollToSection(newSection))
        .finally(() => {
          isScrolling.current = false;
        });
    } else {
      isScrolling.current = false;
    }
  };

  const handleWheelScroll = (event) => {
    if (Math.abs(event.deltaY) > 20) {
      handleScroll(event.deltaY);
    }
  };

  const handleTouchStart = (event) => {
    event.preventDefault(); 
    touchStartY.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
    const touchEndY = event.touches[0].clientY;
    const delta = touchStartY.current - touchEndY;

    if (Math.abs(delta) > 20) {
      handleScroll(delta);
      touchStartY.current = touchEndY;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      handleScroll(1);
    } else if (event.key === 'ArrowUp') {
      handleScroll(-1);
    }
  };

  const handleMouseDown = (event) => {
    dragStartY.current = event.clientY;
    isDragging.current = true;
  };

  const handleMouseMove = (event) => {
    if (isDragging.current) {
      const delta = dragStartY.current - event.clientY;
      if (Math.abs(delta) > 20) {
        handleScroll(delta);
        dragStartY.current = event.clientY;
      }
    }
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  useEffect(() => {
    window.addEventListener('wheel', handleWheelScroll);
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove, { passive: false }); 
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('wheel', handleWheelScroll);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [currentSection]);

  return (
    <div className="home">
      <Header currentSection={currentSection} onLogoClick={scrollToSectionZero} />
      <div className="scroll-container">
        <div className="contents" id="section-0">
          <Card
            background={{ video: "/assets/content/client/home/reels_home_v2.mp4" }}
            width="100%"
            showDown={false}
            darkenBackground={true}
          >
            <div className="logo_home">
              <Logo
                width={logoWidth}
                imageSrc={"/assets/content/nav/logo.png"}
                height="auto"
                currentSection={currentSection}
                hideLogo="flex"
              />
            </div>
            <div className="logos_down">
              <div className="content">
                <div className="logo_tipe">
                  <div className="contente">
                    <img className='logo_mm' src="/assets/content/icons/logo_mm.png" alt="logo_mm" />
                    <p>Top 10 agências mais criativas do Brasil em 2023</p>
                  </div>
                  <div className="contente">
                    <img className='logo_canneslions' src="/assets/content/icons/logo_canneslions.png" alt="logo_canneslions" />
                    <p>Top 10 Brasil das agências mais premiadas dos últimos 10 anos</p>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="contente_box">
                  <img
                    className={logosData[currentLogoIndex].className}
                    src={logosData[currentLogoIndex].imgSrc}
                    alt={`logo_${currentLogoIndex}`}
                    onLoad={handleImageLoad}
                    style={{ display: isImageLoaded ? 'block' : 'none' }} 
                  />
                  {isImageLoaded && <p>{logosData[currentLogoIndex].text}</p>}
                </div>
              </div>

              <div className="arrow-down" onClick={() => scrollToSection(1)}>
                <div className="lines-container">
                  <div className="line"></div>
                  <div className="line"></div>
                  <div className="line"></div>
                </div>
              </div>

              <div className="content">
                <div className="logo_tipe">
                  <div className="contente">
                    <img className='effie' src="/assets/content/icons/logo_effie.png" alt="logo_effie" />
                    <p>Top 5 agências mais premiadas em 2023</p>
                  </div>
                  <div className="contente kantar">
                    <img className='logo_kantar' src="/assets/content/icons/logo_kantar.png" alt="logo_kantar" />
                    <p>Top 3 comerciais mais criativos e eficazes do Brasil</p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        {sections.map((ideia, index) => (
          <div
            key={index + 1}
            ref={(el) => (sectionsRef.current[index + 1] = el)}
            id={`section-${index + 1}`}
            className="contents"
          >
            <Card
              ideia={ideia}
              width="100%"
              height='100vh'
              contentPosition="end"
            />
          </div>
        ))}
        <div
          key={sections.length + 1}
          ref={(el) => (sectionsRef.current[sections.length + 1] = el)}
          id={`section-${sections.length + 1}`}
          className="footer_section"
        >
          <div className="sub">
            <Footer showImg={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
