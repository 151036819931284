import React from 'react';
import './Info.css';

const Info = ({ text }) => {
    return (
        <div className="info">
            {text.map((par, index) => (
                <p key={index}>{par}</p>
            ))}
        </div>
    );
};

export default Info;
