import React from 'react';
import './Contact.css';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

const Contact = ({ currentSection }) => {
    return (
        <div className="contact">
            <Header currentSection={currentSection} />
            <div className="footer_section">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3655.8345031749577!2d-46.69925302456103!3d-23.61026757876621!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce50cd22bb0037%3A0x7b674c8519d07723!2sFCB%20Brasil!5e0!3m2!1sen!2sbr!4v1725275032710!5m2!1sen!2sbr"
                    style={{ border: '0' }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <div className="contact_footer">
                <Footer />
                </div>
            </div>
        </div>
    );
};

export default Contact;
