import React from 'react';
import './App.css';
import AppRoutes from '../src/router/AppRouter'; 
import { BrowserRouter as Router } from 'react-router-dom'; 

function App() {

  return (
    <Router>
      <div className='App'>
        <div className='Content'>
          <AppRoutes />
        </div>
      </div>
    </Router>
  );
}

export default App;
