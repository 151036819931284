import React, { useState, useRef, useEffect } from 'react';
import './Video.css';

const Video = ({ src, autoPlay = false, muted = false, loop = false, controls = true, poster, isFirst = false }) => {
    const [isPaused, setIsPaused] = useState(!autoPlay);
    const [isImageVisible, setIsImageVisible] = useState(true);
    const videoRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPaused) {
                videoRef.current.play();
                setIsImageVisible(false);
            } else {
                videoRef.current.pause(); 
                setIsImageVisible(true);
            }
            setIsPaused(prev => !prev); 
        }
    };

    const handleVideoPlay = () => {
        setIsImageVisible(false);
        setIsPaused(false); 
    };

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.addEventListener('play', handleVideoPlay);
            return () => {
                video.removeEventListener('play', handleVideoPlay);
            };
        }
    }, []);

    return (
        <div className={`video-container ${isFirst ? 'full-width' : ''}`}>
            {isImageVisible && poster && (
                <div className="video-poster" onClick={handlePlayPause}>
                    <img 
                        src={poster} 
                        alt="Video poster" 
                        className="poster-image"
                    />
                    <div className="play-overlay">
                        <div className="play-icon"><ion-icon name="play"></ion-icon></div>
                    </div>
                </div>
            )}
            <video 
                ref={videoRef}
                controls={controls}
                autoPlay={autoPlay}
                muted={muted}
                loop={loop}
                playsInline 
                style={{ width: '100%'}}
            >
                <source src={src} type="video/mp4" />
            </video>
        </div>
    );
};

export default Video;
