import React from 'react';
import './Info2.css';

const Info2 = ({ text }) => {
    return (
        <div className="info2">
            {Array.isArray(text) ? (
                text.map((par, index) => <p key={index}>{par}</p>)
            ) : (
                <p>{text}</p>
            )}
        </div>
    );
};

export default Info2;
