import React from 'react';
import './Button.css';

const Button = ({ text, onClick }) => {
  return (
    <div className="button" onClick={onClick}>
      <span className='btn'>
        {text}
        <ion-icon name="arrow-forward-outline"></ion-icon>
      </span>
    </div>
  );
};

export default Button;
