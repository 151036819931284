import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import './Tape.css';

const Tape = () => {
  const [isPaused, setIsPaused] = useState(false);

  return (
    <div
      className={`tape ${isPaused ? 'paused' : ''}`}
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      <div className="scrolling-content">
        {Array.from({ length: 1000 }).map((_, index) => (
          <Link key={index} to="/works" className="btn-link">
            <span className="btn">Ver mais</span>
          </Link>
        ))}
        {Array.from({ length: 1000 }).map((_, index) => (
          <Link key={index + 1000} to="/works" className="btn-link">
            <span className="btn">Ver mais</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Tape;
