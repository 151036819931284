import React from 'react';
import './Gallery.css';

const defaultVideoDefaults = {
    autoPlay: true,
    loop: true,
    muted: true,
    controls: false
};

const Gallery = ({ itens, altura = 'auto', minWidth = '100px', maxWidth = '200px', videoDefaults = defaultVideoDefaults }) => {
    const getItemWidth = () => {
        const min = parseFloat(minWidth);
        const max = parseFloat(maxWidth); 
        return `${Math.min(max, min)}px`; 
    };

    return (
        <div className="gallery">
            {itens.map((item, index) => {
                const { type, source, autoPlay, loop, muted, controls } = item;
                const itemWidth = getItemWidth(); 

                return type === 'video' ? (
                    <video
                        key={index}
                        src={source}
                        style={{ height: altura, width: itemWidth }}
                        autoPlay={autoPlay !== undefined ? autoPlay : videoDefaults.autoPlay}
                        loop={loop !== undefined ? loop : videoDefaults.loop}
                        muted={muted !== undefined ? muted : videoDefaults.muted}
                        controls={controls !== undefined ? controls : videoDefaults.controls}
                        aria-label={`Video ${index + 1}`} 
                        playsInline
                    />
                ) : (
                    <img
                        key={index}
                        src={source}
                        style={{ height: altura, width: itemWidth }}
                        alt={`Gallery item ${index + 1}`}
                    />
                );
            })}
        </div>
    );
};

export default Gallery;
