// Text.js
import React from 'react';
import './Text.css';

const Text = ({ text, fontSize }) => {
  const style = {
    fontSize: fontSize || '', 
  };

  return (
    <div className="text">
      <p style={style}>{text}</p>
    </div>
  );
};

export default Text;
